import React from "react";
import Slider from "react-slick";

export default function NewsSlider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <Slider {...settings} className="newsSlider">
            <a href="https://www.benzinga.com/pressreleases/23/07/ac33214305/hashfair-revolutionizing-online-gaming-with-blockchain-powered-transparency-and-fairness" target="_blank" className="hoimg">
                <div className="">
                    <img src="news-images/newsbanner-1.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a>
            <a href="https://www.streetinsider.com/Accesswire/HashFair%3A%2BRevolutionizing%2BOnline%2BGamin[…]hain-Powered%2BTransparency%2Band%2BFairness/21904627.html" target="_blank"
                className=" hoimg">
                <div>
                    <img src="news-images/newsbanner-2.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a>
            <a href="https://www.digitaljournal.com/pr/news/accesswire/hashfair-revolutionizing-online-gaming-with-blockchain-powered-transparency-and-fairness" target="_blank" className=" hoimg">
                <div>
                    <img src="news-images/newsbanner-3.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a>
            {/* <a href="https://www.bloomberg.com/press-releases/2023-07-13/hashfair-revolutionizing-online-gaming-with-blockchain-powered-transparency-and-fairness" target="_blank" className=" hoimg">
                <div>
                    <img src="news-images/newsbanner-4.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a> */}
            <a href="https://finance.yahoo.com/cryptocurrencies/hashfair-revolutionizing-online-gaming-blockchain-124000571.html?fr=sycsrp_catchall" target="_blank"
                className=" hoimg">
                <div>
                    <img src="news-images/newsbanner-5.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a>
            {/* <a href="https://www.marketwatch.com/press-release/hashfair-revolutionizing-online-gaming-with-[…]ered-transparency-and-fairness-2b75db69?mod=search_headline" target="_blank" className=" hoimg">
                <div>
                    <img src="news-images/newsbanner-6.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a> */}
            {/* <a href="/" target="_blank" className=" hoimg">
                <div>
                    <img src="news-images/newsbanner-7.jpg" width={"202"} height={"122"} className="" alt="newsbanner" />
                </div>
            </a> */}
        </Slider>
    );
}