import React from 'react'
import Banner from '../../Components/Banner/Banner'
import Faqs from '../../Components/Faqs/Faqs'
import Footer from '../../Components/Footer/Footer'
const Home = () => {
    return (
        <>
            <Banner />
            <Faqs />
            <Footer />
        </>
    )
}

export default Home