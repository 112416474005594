import logo from '../../Assets/images/logo.gif'
import { Link } from 'react-scroll';
import LazyLoad from 'react-lazyload';

const Header = () => {


    return (
        <>
            <header className="header_section header_transparent sticky-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="main_header d-flex justify-content-between align-items-center">
                                <div className="header_logo">
                                    <a className="sticky_none" href="#!!">

                                        <LazyLoad className="logoMainHead" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>

                                            <img src={logo} className='header-logo' alt="logo" />
                                        </LazyLoad>
                                    </a>
                                </div>


                                <div className="main_menu d-none d-lg-block">
                                    <nav>
                                        <ul className="d-flex">
                                            <li><Link href="#!" to="aboutus" smooth={true} duration={0}>About Us</Link></li>
                                            <li><Link href="#!" to="keyfeatures" smooth={true} duration={0}>Key Features</Link></li>
                                            <li><Link href="#!" to="roadlmap" smooth={true} duration={0}>RoadMap</Link></li>
                                            <li><Link href="#!" to="tokenomics" smooth={true} duration={0}>Tokenomics</Link></li>
                                            <li><Link href="#!" to="nft" smooth={true} duration={0}>NFT</Link></li>
                                            <li><a href="https://drive.google.com/drive/folders/1bQr0WymmiWyIorByRc5xPABONayV0agB?usp=drive_link" target="_blank" >Media Kit </a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header