import React from 'react';
import arrrowIcon2 from '../../Assets/images/arrrow-icon2.webp'
import game1 from '../../Assets/images/web3-lottery.webp'
import game2 from '../../Assets/images/web3-prediction.webp'
import game3 from '../../Assets/images/web3-multi-roulette.webp'
import trust from '../../Assets/images/key-features/trust.png'
import immutableGame from '../../Assets/images/key-features/immutableGame.png'
import distribution from '../../Assets/images/key-features/distribution.png'
import decentralized from '../../Assets/images/key-features/decentralized.png'
import lifetimeincome from '../../Assets/images/key-features/lifetimeincome.png'
import communitybased from '../../Assets/images/key-features/communitybased.png'
import tokenomics from '../../Assets/images/tokenomics.png'
import ScrollAnimation from 'react-animate-on-scroll';
import ReactPlayer from 'react-player';
import polygon from '../../Assets/images/polygon.svg'
import metaMask from '../../Assets/images/metaMask-icon.svg'
import trustwallet from '../../Assets/images/trustwallet.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyLoad from 'react-lazyload';
import takenicon from '../../Assets/images/takenicon.svg'
import bannerVideo from '../../Assets/images/banner-video.mp4'
import aboutBanner from '../../Assets/images/about-banner.mp4'
import nftLvl1 from '../../Assets/images/nfts/hashfair-nft-lvl1.mp4'
import nftLvl2 from '../../Assets/images/nfts/hashfair-nft-lvl2.mp4'
import nftLvl3 from '../../Assets/images/nfts/hashfair-nft-lvl3.mp4'
import nftLvl4 from '../../Assets/images/nfts/hashfair-nft-lvl4.mp4'
import nftLvl5 from '../../Assets/images/nfts/hashfair-nft-lvl5.mp4'
import nftLvl6 from '../../Assets/images/nfts/hashfair-nft-lvl6.mp4'
import nftLvl7 from '../../Assets/images/nfts/hashfair-nft-lvl7.mp4'

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper.css';
// import SaleCountdown from '../SaleCountdown/SaleCount';
import NewsSlider from '../News-Slider/Newsslider';
import MediaSlider from '../Media-Slider/MediaSlider';
import { FaGoogleDrive } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Contracts from '../Contracts/Contracts';


SwiperCore.use([Navigation, Pagination, Autoplay]);

const Banner = () => {
    const nftTitles = [
        {
            key: `${nftLvl1}`,
            value: "BLINKERS"
        },
        {
            key: `${nftLvl2}`,
            value: "SLOTTERSPOT"
        },
        {
            key: `${nftLvl3}`,
            value: "SPINWIN"
        },
        {
            key: `${nftLvl4}`,
            value: "WILDCARD"
        },
        {
            key: `${nftLvl5}`,
            value: "THUNDERBIRD"
        },
        {
            key: `${nftLvl6}`,
            value: "JACKPOT"
        },
        {
            key: `${nftLvl7}`,
            value: "KINGPIN"
        }
    ];

    const swiperRef = React.useRef(null);


    return (
        <>
            {/* <section className='salecount'>
                <SaleCountdown />
            </section> */}
            <div className="newsmarquee">
                <div class="marquee">
                    <marquee behavior="scroll" direction="Left" scrollamount="12">
                        <span>
                            HFG Withdrawals Are Now Live on HashFair Games... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="news-images/hashmoblogo.webp" width="40" height="40" alt="Hash Fair Logo" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            HFG Coin Staking is Now live... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="news-images/hashmoblogo.webp" width="40" height="40" alt="Hash Fair Logo" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Mainnet Web3 Games are Live... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="news-images/hashmoblogo.webp" width="40" height="40" alt="Hash Fair Logo" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Hashfair NFT Marketplace is live Now, Go and Buy Your Share with HFG... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="news-images/hashmoblogo.webp" width="40" height="40" alt="Hash Fair Logo" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </marquee>
                </div>
            </div>

            <div class="web3gamesfix">
                <Link to="https://hashfair.games/" target='_blank'>
                    <img src="roulettewheelanim.webp" width="50" height="50" alt="web3 games" />
                </Link>
            </div>

            <section className="hero_banner_section d-flex align-items-center mb-10">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 hero_banner_inner">
                            <div className="hero_content">
                                <h1>Experience Web3 Gaming World</h1>
                                <p>Welcome to HashFair, the forefront of decentralized and DAO-based online gaming. Here, every game outcome is tied to blockchain transaction hashes, ensuring an unparalleled level of fairness and transparency.</p>
                                <p>Step into HashFair's revolutionary platform, where each roll, spin, or deal is reliably calculated using hash values, defining the future of secure and trustworthy online gaming.</p>
                                {/* <div className='mb-3'>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className='d-flex flex-wrap justify-content-center justify-content-md-start'>
                                            <div className="sing_up_btn about_btn">
                                                <a className="btn btn-link"
                                                    href="https://hashfair.games/"
                                                    target="_blank" rel="noreferrer">
                                                    Buy HFG
                                                    <img width="20" height="20" src={arrrowIcon2} alt="" /> </a>
                                            </div>

                                            <div className="sing_up_btn countbtn ms-4 ">
                                                <a
                                                    className="btn btn-link"
                                                    href="https://hashfair.games/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Stake HFG
                                                    <img width="20" height="20" src={arrrowIcon2} alt="btn" />
                                                </a>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <ScrollAnimation animateIn="fadeIn">
                                    <div className='d-flex flex-wrap justify-content-center justify-content-md-start'>
                                        <div className="sing_up_btn about_btn">
                                            <a className="btn btn-link"
                                                href="https://hashfair.games/"
                                                target="_blank" rel="noreferrer">
                                                HashFair Games
                                                <img width="20" height="20" src={arrrowIcon2} alt="" /> </a>
                                        </div>

                                        <div className="sing_up_btn countbtn ms-4 ">
                                            <a
                                                className="btn btn-link"
                                                href="https://hashfair.games/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Web3 Games
                                                <img width="20" height="20" src={arrrowIcon2} alt="btn" />
                                            </a>
                                        </div>
                                    </div>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                        <div className="col-lg-6 order-first order-md-last">
                            <LazyLoad className="hero_position_img" width="650" height="650" once placeholder={
                                <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0">
                                    <path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                        <animateTransform
                                            attributeName="transform"
                                            attributeType="XML"
                                            type="rotate"
                                            dur="1s"
                                            from="0 50 50"
                                            to="360 50 50"
                                            repeatCount="indefinite" />
                                    </path>
                                </svg>
                            }>
                                <ReactPlayer
                                    className='react-player bannerVideo'
                                    url={bannerVideo}
                                    width='100%'
                                    height='100%'
                                    muted
                                    loop
                                    playing={true}
                                    playsinline={true}
                                    autoPlay={true}
                                />
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_title h2 d-flex align-items-center mb-10">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-10 col-lg-5 hero_banner_inner mx-auto">
                            <div className="hero_content">
                                <h2 className='text-center mb-4'>Quick Links</h2>
                                <div className='mb-3'>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className='d-flex flex-wrap justify-content-center gap-4'>
                                            <div className="sing_up_btn about_btn">
                                                <a className="btn btn-link"
                                                    href="https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C1[…]e8F&outputCurrency=0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf"
                                                    target="_blank" rel="noreferrer">
                                                    Buy HFG
                                                    <img width="20" height="20" src={arrrowIcon2} alt="" /> </a>
                                            </div>
                                            <div className="sing_up_btn about_btn">
                                                <a className="btn btn-link"
                                                    href="https://marketplace.hashfair.io/"
                                                    target="_blank" rel="noreferrer">
                                                    Buy NFT
                                                    <img width="20" height="20" src={arrrowIcon2} alt="" /> </a>
                                            </div>
                                            <div className="sing_up_btn countbtn">
                                                <a
                                                    className="btn btn-link"
                                                    href="https://stake.hashfair.io"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Stake HFG
                                                    <img width="20" height="20" src={arrrowIcon2} alt="btn" />
                                                </a>
                                            </div>
                                            <div className="sing_up_btn about_btn">
                                                <a className="btn btn-link"
                                                    href="https://stake-nft.hashfair.io/"
                                                    target="_blank" rel="noreferrer">
                                                    Stake NFT
                                                    <img width="20" height="20" src={arrrowIcon2} alt="" /> </a>
                                            </div>
                                            <div className="sing_up_btn countbtn">
                                                <a
                                                    className="btn btn-link"
                                                    href="https://hashfair.games/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    HashFair Games
                                                    <img width="20" height="20" src={arrrowIcon2} alt="btn" />
                                                </a>
                                            </div>
                                            <div className="sing_up_btn countbtn">
                                                <a
                                                    className="btn btn-link"
                                                    href="https://hashfair.games/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Web3 Games
                                                    <img width="20" height="20" src={arrrowIcon2} alt="btn" />
                                                </a>
                                            </div>
                                            <div className="sing_up_btn about_btn">
                                                <a className="btn btn-link"
                                                    href="https://drive.google.com/drive/folders/1bQr0WymmiWyIorByRc5xPABONayV0agB?usp=drive_link" target="_blank" >
                                                    Media Kit
                                                    <FaGoogleDrive /> </a>
                                            </div>
                                            <div className="sing_up_btn about_btn">
                                                <a className="btn btn-link"
                                                    href="https://docs.hashfair.io/"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    WhitePaper
                                                    <img width="20" height="20" src={arrrowIcon2} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                {/* <ScrollAnimation animateIn="fadeIn">
                                    <div className='d-flex flex-wrap justify-content-center justify-content-md-start'>
                                        
                                    </div>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         

            <section className="about_section mt-5" id='aboutus'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <LazyLoad className="about_thumb" width={650} height={650} once placeholder={
                                <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0">
                                    <path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                        <animateTransform
                                            attributeName="transform"
                                            attributeType="XML"
                                            type="rotate"
                                            dur="1s"
                                            from="0 50 50"
                                            to="360 50 50"
                                            repeatCount="indefinite" />
                                    </path>
                                </svg>
                            }>
                                <ReactPlayer
                                    className='react-player aboutVideo'
                                    url={aboutBanner}
                                    muted
                                    width='100%'
                                    height='100%'
                                    loop
                                    playing={true}
                                    playsinline={true}
                                    autoPlay={true}
                                />
                            </LazyLoad>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_sidebar">
                                <div className="about_title mb-3">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <h2>About HashFair</h2>
                                    </ScrollAnimation>
                                </div>
                                <div className="about_desc">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <p>HashFair is an innovative online casino platform that utilizes blockchain technology to establish a decentralized gaming environment. The platform's unique feature is its use of transaction hashes from the blockchain to determine game outcomes, ensuring total transparency and trust in the gaming process.</p>
                                        <p> Every game result is securely tied to these transaction hashes, making the process immune to tampering and outside the control of any entity, including HashFair. This method allows players to verify the fairness of each game, promoting a trustworthy and honest platform.</p>
                                    </ScrollAnimation>
                                </div>
                                {/* <ScrollAnimation animateIn="fadeIn">
                                   
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Contracts />
            <section className="upcoming_gaming_section mb-125">
                <div className="container">
                    <div className="section_title text-center">
                        <ScrollAnimation animateIn="fadeIn">
                            <h2>Supported By</h2>
                            <p>This powerful combination of Polygon, MetaMask, and Trust Wallet provides a financial services. </p>
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation animateIn="fadeIn">
                        <div className="upcoming_gaming_inner">
                            <div className="upcoming_gaming_list d-flex justify-content-between align-items-center mb-30">
                                <div className="upcoming_gaming_text">
                                    <h3>Integrated Blockchain & Preferred Wallets</h3>
                                </div>
                                <div className="upcoming_gaming_thumb d-flex align-items-center">
                                    <LazyLoad className="single_upcoming_thumb" height={650} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="97" height="119" src={polygon} alt="polygon" />
                                    </LazyLoad>
                                    <LazyLoad className="single_upcoming_thumb" height={650} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="87" height="87" src={metaMask} alt="metaMask" />
                                    </LazyLoad>
                                    <LazyLoad className="single_upcoming_thumb" height={650} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="93" height="120" src={trustwallet} alt="trustwallet" />
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </section>
      
            <section className="gaming_world_section mb-100">
                <div className="container-fluid">
                    <div className="section_title text-center mb-5">
                        <ScrollAnimation animateIn="fadeIn">
                            <h2>HashFair In the NEWS</h2>
                        </ScrollAnimation>
                    </div>
                    <div className="row mx-auto overflow-hidden">
                        <div className="col-12">
                            <NewsSlider />
                        </div>
                    </div>
                </div>
            </section>
            <section className="gaming_world_section mb-100">
                <div className="section_title text-center mb-5">
                    <ScrollAnimation animateIn="fadeIn">
                        <h2>HashFair In the Media</h2>
                    </ScrollAnimation>
                </div>
                {/* <ScrollAnimation animateIn="fadeIn">
                   
                </ScrollAnimation> */}
                <div className="row mx-auto overflow-hidden">
                    <div className="col-12">
                        <MediaSlider />
                    </div>
                </div>
                {/* <div className="container">

                    <div className="gaming_world_inner newsInner">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="single_gaming_world">
                                    <a href="https://twitter.com/PolygonDaily/status/1684123567506743298?t=Re4LAgiUKiJIB4zeaSlo5w&s=19" target="_blank" rel="noreferrer">
                                        <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                            <img width="300" height="300" src={news1} alt="news1" />
                                        </LazyLoad>
                                        <div className="gaming_world_text">
                                            <h4>Polygon Daily</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="single_gaming_world">
                                    <a href="https://youtu.be/tN4PmZmSank" target="_blank" rel="noreferrer">
                                        <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                            <img width="300" height="300" src={news2} alt="news2" />
                                        </LazyLoad>
                                        <div className="gaming_world_text">
                                            <h4>NFTs Guide</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="single_gaming_world">
                                    <a href="https://www.coingabbar.com/en/crypto-airdrops/hashfair-games-hfg-token-latest-airdrop-giveaway" target="_blank" rel="noreferrer">
                                        <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                            <img width="300" height="300" src={news3} alt="news3" />
                                        </LazyLoad>
                                        <div className="gaming_world_text">
                                            <h4>Coingabbar</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="single_gaming_world">
                                    <a href="https://youtu.be/yZZXpidP0V8" target="_blank" rel="noreferrer">
                                        <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                            <img width="300" height="300" src={news4} alt="news4" />
                                        </LazyLoad>
                                        <div className="gaming_world_text">
                                            <h4>CryptoCoinsNFT Gaming</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            <section className="blog_section mb-90" id='keyfeatures'>
                <div className="container">
                    <div className="section_title text-center mb-70">
                        <ScrollAnimation animateIn="fadeIn">
                            <h2>Key Features</h2>
                            <p>Unlocking Innovation, Empowering Possibilities: HashFair's Key Features Transform the Game!</p>
                        </ScrollAnimation>
                    </div>
                    <div className="row blog_inner">
                        <div className="col-lg-6 col-md-6">
                            <div className="single_blog d-flex align-items-center">
                                <LazyLoad className="blog_thumb" height={200} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <img width="93" height="120" src={trust} alt="trust" />
                                </LazyLoad>
                                <div className="blog_content">
                                    <h3>Transparency and Trust</h3>
                                    <div className="blog_date">
                                        <p>We leverage blockchain technology to ensure transparency and trust. Every game outcome is derived from decentralized smart contracts, providing a verifiable and tamper-proof record of fairness.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="single_blog d-flex align-items-center">
                                <LazyLoad className="blog_thumb" height={200} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <img width="93" height="120" src={immutableGame} alt="immutableGame" />
                                </LazyLoad>
                                <div className="blog_content">
                                    <h3>Immutable Game Rules</h3>
                                    <div className="blog_date">
                                        <p>Game rules on HashFair are written on decentralized smart contracts, ensuring they remain unalterable and transparent. Players can trust that the rules are consistently applied, providing a fair gaming experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="single_blog d-flex align-items-center">
                                <LazyLoad className="blog_thumb" height={200} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <img width="93" height="120" src={distribution} alt="distribution" />
                                </LazyLoad>
                                <div className="blog_content">
                                    <h3>Fair Profit Distribution</h3>
                                    <div className="blog_date">
                                        <p>We believe in community-driven success. HashFair automatically distributes 80% of profits to an exclusive NFT pool, benefiting the community. Additionally, 20% of profits are burned, increasing the value for token holders.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="single_blog d-flex align-items-center">
                                <LazyLoad className="blog_thumb" height={200} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <img width="93" height="120" src={decentralized} alt="decentralized" />
                                </LazyLoad>
                                <div className="blog_content">
                                    <h3>Decentralized Dealer</h3>
                                    <div className="blog_date">
                                        <p>Step into the future of gaming at HashFair Games, where we introduce the world's first decentralized dealer!Experience the thrill of playing your favorite games with complete transparency and fairness.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="single_blog d-flex align-items-center">
                                <LazyLoad className="blog_thumb" height={200} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <img width="93" height="120" src={lifetimeincome} alt="lifetimeincome" />
                                </LazyLoad>
                                <div className="blog_content">
                                    <h3>Lifetime Income</h3>
                                    <div className="blog_date">
                                        <p>Secure your financial future with HashFair! Introducing an unprecedented offer: if you happen to lose a bet, we've got you covered. We're revolutionizing the gaming industry by providing a lifetime source of income as an NFT (Non-Fungible Token).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="single_blog d-flex align-items-center">
                                <LazyLoad className="blog_thumb" height={200} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <img width="93" height="120" src={communitybased} alt="communitybased" />
                                </LazyLoad>
                                <div className="blog_content">
                                    <h3>Community Based</h3>
                                    <div className="blog_date">
                                        <p>Join the HashFair community and become a part of something extraordinary! At HashFair, we believe in the power of sharing. That's why we distribute profits among our community members.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* gaming  world section start */}
            <section className="gaming_world_section mb-100">
                <div className="container">
                    <div className="section_title text-center">
                        <ScrollAnimation animateIn="fadeIn">
                            <h2>YOU ARE MOST WELCOME <br />
                                IN GAMING WORLD.</h2>
                        </ScrollAnimation>
                    </div>
                    <div className="gaming_world_inner">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="single_gaming_world">
                                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="300" height="300" src={game3} alt="game3" />
                                    </LazyLoad>
                                    <div className="gaming_world_text">
                                        <h3>Live</h3>
                                        <p>Web3-Multiplayer Roulette</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="single_gaming_world">
                                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="300" height="300" src={game2} alt="game2" />
                                    </LazyLoad>
                                    <div className="gaming_world_text">
                                        <h3>Live</h3>
                                        <p>Web3-Prediction </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="single_gaming_world">
                                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="300" height="300" src={game1} alt="game1" />
                                    </LazyLoad>
                                    <div className="gaming_world_text">
                                        <h3>Live</h3>
                                        <p>Web3-Lottery</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="roadmapmain mb-100" id='roadlmap'>
                <div className="container">
                    <div className="section_title text-center">
                        <ScrollAnimation animateIn="fadeIn">
                            <h2>Road Map</h2>
                            <p>Paving the Path to Success: HashFair's Roadmap Unveils the Journey Towards a Decentralized Future.</p>
                        </ScrollAnimation>
                    </div>
                    <div className="timeline">
                        <div className="entry">
                            <strong className='yeartitle'>2023</strong>
                            <div className="title">
                                <p>Concept Development and Research ✅</p>
                            </div>
                            <div className="body">
                                <ul>
                                    <li>Conduct market research to identify target audience and competitors.</li>
                                    <li>Define the vision and mission of Hash Fair.</li>
                                    <li>Establish the core team and allocate resources.</li>
                                    <li>Develop a comprehensive understanding of blockchain technology and its application in the online gaming industry.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="entry">
                            <div className="title">
                                <p>Platform Development ✅</p>
                            </div>
                            <div className="body">
                                <ul>
                                    <li>Build the Hash Fair platform with a focus on a user-friendly interface and seamless user experience.</li>
                                    <li>Implement decentralized smart contracts for transparent and fair gaming outcomes.</li>
                                    <li>Integrate secure and robust payment systems, including support for cryptocurrencies.</li>
                                    <li>Develop a wide range of captivating and engaging online games.</li>
                                    <li>Test and optimize the platform for scalability, security, and performance.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="entry">
                            <div className="title">
                                <p>Milestones and Achievements ✅</p>
                            </div>
                            <div className="body">
                                <ul>
                                    <li>Whitepaper Launch - Delivered: We successfully launched our whitepaper, outlining our project's vision and goals.</li>
                                    <li>Seed Round - Conducted: We successfully conducted a seed funding round, securing support for our project's development.</li>
                                    <li>ICO Round - Conducted: Our initial coin offering round has been completed, allowing us to raise the necessary capital for further development.</li>
                                    <li>Decentralized Dealer Contract - Delivered: The decentralized dealer contract is operational, providing transparency and fairness in gaming.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="entry">
                            <div className="title">
                                <p>Delivering Success in Q2-Q3 2023✅ </p>
                            </div>
                            <div className="body">
                                <ul>
                                    <li>Web 2 Games - Delivered: We launched a series of Web 2 games, and currently, 20 games are live and actively enjoyed by our users.</li>
                                    <li>Web 3 Games - Delivered: Our Web 3 games, built on blockchain smart contracts, have been delivered. Three of these games are already live, providing decentralized gaming experiences.
</li>
                                    <li>NFT Contracts - Delivered: We have successfully deployed seven NFT contracts, enabling users to trade and own unique digital assets.</li>
                                    <li>NFT Staking Contracts - Delivered: NFT staking contracts have been implemented, allowing users to earn rewards by participating in our ecosystem.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="entry">
                            <strong className='yeartitle'>Future</strong>
                            <div className="title">
                                <p> Forward into Q4 2023</p>
                            </div>
                            <div className="body">
                                <ul>
                                    <li>Expanded Game Library: We will continue to expand our game library, introducing new and exciting gaming experiences for our users.</li>
                                    <li>Enhanced NFT Ecosystem: We plan to further enhance our NFT ecosystem, introducing new features and collaborations to make NFT ownership and trading more engaging.</li>
                                    <li>Community Engagement: We will focus on building a strong community around HashFair Games, with regular events, tournaments, and incentives for our users.</li>
                                    {/* <li>Establish a decentralized NFT marketplace for users to buy, sell, and trade their exclusive digital assets.</li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="entry">
                            <div className="title">
                                <p>Building the Future</p>
                            </div>
                            <div className="body">
                                <ul>
                                    <li>Integration with More Blockchains: We aim to expand our blockchain compatibility, allowing users to interact with our platform using various blockchain networks.</li>
                                    <li>Advanced Smart Contracts: We will work on developing more advanced smart contracts to improve the functionality and security of our platform.</li>
                                    <li>Partnerships and Collaborations: We will seek strategic partnerships and collaborations with other projects and organizations in the blockchain and gaming industry.</li>
                                    {/* <li>Conduct regular audits and security assessments to maintain a high level of trust and security for users.</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="gaming_world_section mb-100" id='tokenomics'>
                <div className="container">
                    <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2>Tokenomics</h2>
                        <p>Read our Tokenomics here</p>
                    </div>

                    <div className="row align-items-center mt-5">
                        <div className="col-lg-7 col-sm-12">
                            <LazyLoad className="tokenomicsInner" height={800} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                <img width="800" height="800" src={tokenomics} alt="tokenomics" />
                            </LazyLoad>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <div className="tokenomicsTable">
                                <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                                    <LazyLoad className="takenicon mb-3" height={100} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                        <img width="100" height="100" src={takenicon} alt="takenicon" />
                                    </LazyLoad>
                                    <strong className='mb-4'>Token Details</strong>
                                </div>
                                <table className="table table-striped table-dark">
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>HashFair</td>
                                        </tr>
                                        <tr>
                                            <td>Symbol</td>
                                            <td>HFG</td>
                                        </tr>
                                        <tr>
                                            <td>Total Supply</td>
                                            <td>2,000,000,000 HFG</td>
                                        </tr>
                                        <tr>
                                            <td>HFG Contract Address</td>
                                            <td><a href="https://polygonscan.com/token/0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf" target="_blank" rel="noreferrer">0x681E...03fcf</a></td>
                                        </tr>
                                        <tr>
                                            <td>HUSD Contract Address</td>
                                            <td><a href="https://polygonscan.com/token/0xc4f58cdce4768d78f36cb3bd9652fcbace94ccd6" target="_blank" rel="noreferrer">0xc4f5...4ccd6</a></td>
                                        </tr>
                                        <tr>
                                            <td>Blockchain</td>
                                            <td>Polygon Mainnet</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* gaming  world section end */}
            {/* popular gaming  section start */}
            <section className="popular_gaming_section mb-100" id='nft'>
                <div className="container">
                    <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <ScrollAnimation animateIn="fadeIn">
                            <h2>Our NFT's</h2>
                            <p>Elevate Your Collection, Embrace the Digital Renaissance: HashFair NFTs Redefine Possibilities!"</p>
                        </ScrollAnimation>
                    </div>
                    <div className="popular_gaming_inner">
                        <Swiper
                            className="reasultPageSwiper"
                            loop={true}
                            spaceBetween={35} // Add the desired gap value here (20px)
                            slidesPerView={3} // Show 3 items per view on desktop
                            navigation={true} // Enable navigation arrows
                            // pagination={{ clickable: true }} // Enable pagination bullets
                            autoplay={{ delay: 3000 }} // Set autoplay delay to 3000ms (3 seconds)
                            onSwiper={(swiper) => (swiperRef.current = swiper)}
                            breakpoints={{
                                // When window width is >= 768px (desktop)
                                768: {
                                    slidesPerView: 3,
                                },
                                // When window width is < 768px (mobile)
                                0: {
                                    slidesPerView: 1,
                                },
                            }}
                        >
                            {nftTitles.map((item, index) => (
                                <SwiperSlide key={index}>
                                    {/* <img src={item} alt={`Slide ${index + 1}`} /> */}
                                    <div className="popular_gaming_thumb">
                                        <a href="#!">
                                            <span className='nftboxtitle'>{`${item.value}`}</span>
                                            <LazyLoad once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                                <ReactPlayer
                                                    className='react-player nftsvideo'
                                                    url={item.key}
                                                    width='100%'
                                                    height='100%'
                                                    muted
                                                    loop
                                                    playing={true}
                                                    playsinline={true}
                                                    autoPlay={true}
                                                />
                                            </LazyLoad>
                                        </a>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* popular gaming section end */}
        </>
    )
}

export default Banner