import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import takenicon from '../../Assets/images/takenicon.svg';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "../../Assets/css/style.css"
// import "../../pop"

const AlertDialog = ({ open, handleClose, content }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >   
            <DialogTitle id="alert-dialog-title">{"Hashfair HFG Staking"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <div className="tokenomicsTable">
                            <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                                {/* <LazyLoad className="takenicon mb-3" height={100} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                            <img width="100" height="100" src={takenicon} alt="takenicon" />
                        </LazyLoad> */}
                                
                            </div>
                            <table className="table table-striped table-dark">
                                <tbody>
                                    <tr>
                                        <td> HFG Staking</td>
                                        <td> <a href="https://polygonscan.com/address/0x42319C5b303B86f08dC6f301290edf32349b8a83" target="_blank" rel="noreferrer">  0x4231...b8a83</a></td>
                                    </tr>
                                    <tr>
                                        <td> HFG Staking</td>
                                        <td> <a href="https://polygonscan.com/address/0xef0AaD57AF88B56F3DB8621EC6566e404de06226" target="_blank" rel="noreferrer">0xef0A...06226</a></td>
                                    </tr>
                                    <tr>
                                        <td> HFG Staking</td>
                                        <td> <a href="https://polygonscan.com/address/0x622D3f9c8bc142CF02ACf39f629072cD9141697b" target="_blank" rel="noreferrer"> 0x622D...1697b</a></td>
                                    </tr>
                                    <tr>
                                        <td> HFG Staking</td>
                                        <td><a href="https://polygonscan.com/address/0x5adb6E853B41A36Ee9Ae9F064795237D8598064C" target="_blank" rel="noreferrer">  0x5adb...8064C</a></td>
                                    </tr>
                                    <tr>
                                        <td> HFG Staking</td>
                                        <td><a href="https://polygonscan.com/address/0x0558f4187ce35D187ae9fD0393C8C21E1527053A" target="_blank" rel="noreferrer"> 0x0558...7053A</a></td>
                                    </tr>
                                    <tr>
                                        <td> HFG Staking</td>
                                        <td><a href="https://polygonscan.com/address/0xBe6dCd46bfF956ac346A4cbEC746EcdCf3C41576" target="_blank" rel="noreferrer"> 0xBe6d...41576</a></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={handleClose} autoFocus>
                 Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AlertDialog2 = ({ open, handleClose, content }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="custom-dialog-title"
            aria-describedby="custom-dialog-description"
        >
            <DialogTitle id="custom-dialog-title">{"Hashfair NFT's"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="custom-dialog-description">
                <div className="tokenomicsTable">
                            <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                                {/* <LazyLoad className="takenicon mb-3" height={100} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                            <img width="100" height="100" src={takenicon} alt="takenicon" />
                        </LazyLoad> */}
                               
                            </div>
                            <table className="table table-striped table-dark">
                                <tbody>
                                    <tr>
                                        <td>Blinkers</td>
                                        <td> <a href="https://polygonscan.com/address/0xc4D241e10C40D7EE50F00B42cdcc27982E607886" target="_blank" rel="noreferrer">0xc4D2...07886</a></td>
                                    </tr>
                                    <tr>
                                        <td>Slotterspot</td>
                                        <td> <a href="https://polygonscan.com/address/0x1B923961b3Ab511391326aF3367a85F2FC44c08B" target="_blank" rel="noreferrer">0x1B92...4c08B</a></td>
                                    </tr>
                                    <tr>
                                        <td>Spinwin</td>
                                        <td> <a href="https://polygonscan.com/address/0xeb00fabe43f89609eD728011718bDBAa2ae3F743" target="_blank" rel="noreferrer">0xeb00...3F743</a></td>
                                    </tr>
                                    <tr>
                                        <td>Jackpot</td>
                                        <td><a href="https://polygonscan.com/address/0x8617b6b37604d8268b7dF0ADe66682B9AB013994" target="_blank" rel="noreferrer"> 0xC35E...F27Df</a></td>
                                    </tr>
                                    <tr>
                                        <td>Thunderbird</td>
                                        <td><a href="https://polygonscan.com/address/0xe8068734bA9026ef7893119F4a722c8F83bB0894" target="_blank" rel="noreferrer">0xe806...B0894</a></td>
                                    </tr>
                                    <tr>
                                        <td>Kingpin</td>
                                        <td><a href="https://polygonscan.com/address/0xE41a3E3d76A6B67bB351Cf913BC3573392dc951F" target="_blank" rel="noreferrer">0x8617...13994</a></td>
                                    </tr>
                                    <tr>
                                        <td>WildCard</td>
                                        <td><a href="https://polygonscan.com/address/0xC35Eb08b3Eb9a8cDd4d0dF30ea1B23bE488F27Df" target="_blank" rel="noreferrer">0xE41a...c951F</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const AlertDialog3 = ({ open, handleClose, content }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="second-custom-dialog-title"
            aria-describedby="second-custom-dialog-description"
        >
            <DialogTitle id="second-custom-dialog-title">{"Hashfair ICO Rounds"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="second-custom-dialog-description">
                <div className="tokenomicsTable">
                         
                            <table className="table table-striped table-dark">
                                <tbody>
                                    <tr>
                                        <td>Hashfair Ico round1</td>
                                        <td> <a href="https://polygonscan.com/address/0x3c31E3362B22b140A06e5613206b177Ca0E918Fc" target="_blank" rel="noreferrer">0x3c31...918Fc</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Ico round2</td>
                                        <td> <a href="https://polygonscan.com/address/0x725d73D826400e9886F17D80d29c15C2A960c27D" target="_blank" rel="noreferrer">0x725d...0c27D</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Ico round3</td>
                                        <td> <a href="https://polygonscan.com/address/0xD14e68429c13F98B50aac52afF4C34Db207f406d" target="_blank" rel="noreferrer">0xD14e...f406d</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Ico round4</td>
                                        <td><a href="https://polygonscan.com/address/0x75B3f9040B4FCcb0011866514C08d3AD00EA04F3 " target="_blank" rel="noreferrer"> 0x75B3...EA04F3</a></td>
                                    </tr>
                                  

                                </tbody>
                            </table>
                        </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};


const Contracts = () => {

    // State for AlertDialog1
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');

    // State for AlertDialog2
    const [openCustom, setOpenCustom] = useState(false);
    const [contentCustom, setContentCustom] = useState('');

    // State for AlertDialog3
    const [openSecondCustom, setOpenSecondCustom] = useState(false);
    const [contentSecondCustom, setContentSecondCustom] = useState('');

    // Handlers for AlertDialog1
    const handleClickOpen = (content) => {
        setContent(content);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // Handlers for AlertDialog2
    const handleClickOpenCustom = (content) => {
        setContentCustom(content);
        setOpenCustom(true);
    };

    const handleCloseCustom = () => {
        setOpenCustom(false);
    };

    // Handlers for AlertDialog3
    const handleClickOpenSecondCustom = (content) => {
        setContentSecondCustom(content);
        setOpenSecondCustom(true);
    };

    const handleCloseSecondCustom = () => {
        setOpenSecondCustom(false);
    };



    return (
        <section className="gaming_world_section mb-100" id='tokenomics'>
            <div className="container">
                <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                    <h2>our Contracts</h2>
                    <p>Read our Contracts here</p>
                </div>

                <div className="row align-items-center mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="tokenomicsTable">
                            <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">
                                {/* <LazyLoad className="takenicon mb-3" height={100} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                            <img width="100" height="100" src={takenicon} alt="takenicon" />
                        </LazyLoad> */}
                                <strong className='mb-4'>Contract Details</strong>
                            </div>
                            <table className="table table-striped table-dark">
                                <tbody>
                                    <tr>
                                        <td>Hashfair Dealer</td>
                                        <td> <a href="https://polygonscan.com/address/0xC0A6FB7D7BDc61C3CB3d2788b15E72f8ed23AB2d" target="_blank" rel="noreferrer">0xC0A6...AB2d</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair NFT Staking</td>
                                        <td> <a href="https://polygonscan.com/address/0x72D99893a0dD44ed7931c4194A343e83492927E4" target="_blank" rel="noreferrer">0x72D9...927E4</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Seed sale</td>
                                        <td> <a href="https://polygonscan.com/address/0x97b6bc3282d029e77874efdcf5b7e42b43b16d62" target="_blank" rel="noreferrer">0x97b6...16d62</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Liquidity</td>
                                        <td><a href="https://polygonscan.com/address/0x0140452d5b68160793b891c5Fa83F6ccb392C6Ae" target="_blank" rel="noreferrer"> 0x0140...2C6Ae</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Roulette</td>
                                        <td><a href="https://polygonscan.com/address/0x346e9425ae6a944d2ba6247ab59fb7e09c964caf" target="_blank" rel="noreferrer">0x346e...64caf</a></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        {/* <LazyLoad className="tokenomicsInner" height={800} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                    <img width="800" height="800" src={tokenomics} alt="tokenomics" />
                </LazyLoad> */}
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="tokenomicsTable">
                            <div className="section_title text-center" data-wow-delay="0.1s" data-wow-duration="1.1s">

                                <strong className='mb-4'>Contract Details</strong>
                            </div>
                            <table className="table table-striped table-dark">
                                <tbody>
                                    <tr>
                                        <td>Hashfair Prediction</td>
                                        <td><a href="https://polygonscan.com/address/0x60C1AEeF5bC1c5D9c56DBEAe4650b153d543Ff3c" target="_blank" rel="noreferrer">0x60C1...3Ff3c</a></td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair Lottery</td>
                                        <td><a href="https://polygonscan.com/address/0xc1972eF6E04Cdd33B3B1ea3106DCF88C198C53Ce" target="_blank" rel="noreferrer">0xc197...C53Ce </a></td>
                                    </tr>

                                    <tr>
                                        <td>Hashfair HFG Staking </td>
                                        <td>
                                            <Button variant="outlined" onClick={() => handleClickOpen("Hashfair HFG Staking Content")}>
                                                View more
                                            </Button>
                                            <AlertDialog open={open} handleClose={handleClose} content={content} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair NFT's </td>
                                        <td>
                                            <Button variant="outlined" onClick={() => handleClickOpenCustom("Custom Dialog Content")}>
                                                View more
                                            </Button>
                                            <AlertDialog2 open={openCustom} handleClose={handleCloseCustom} content={contentCustom} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Hashfair ICO Rounds </td>
                                        <td>
                                            {/* <Button variant="outlined" onClick={() => handleClickOpen("Hashfair ICO Rounds Content")}>
                                                View more
                                            </Button>
                                            <AlertDialog open={open} handleClose={handleClose} content={content} /> */}

                                            {/* Button to open AlertDialog2 */}

                                            <Button variant="outlined" onClick={() => handleClickOpenSecondCustom("Second Custom Dialog Content")}>
                                                View more
                                            </Button>
                                            <AlertDialog3 open={openSecondCustom} handleClose={handleCloseSecondCustom} content={contentSecondCustom} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
};
export default Contracts;
