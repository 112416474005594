import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import '@coreui/coreui/dist/css/coreui.min.css'
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import BigBangStarField from 'react-big-bang-star-field'
import LazyLoad from 'react-lazyload';
import ReactPlayer from 'react-player'
import bgVideo from './Assets/images/bgVideo.mp4'
// import Popup from './Components/Popupbox/Popup'
import SpinCoin from './Assets/images/spin-logo.gif'

const Cursor = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const cursor = document.querySelector('.cursor');
      cursor.setAttribute('style', `top: ${e.pageY - 15}px; left: ${e.pageX - 20}px;`);
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return null;
};
function App() {
  const [hfgPrice, setHfgPrice] = useState(0)


  function getPrice() {
      fetch("https://hfg-price-api.vercel.app/hfg-price")
          .then(response => response.json())
          .then(data => setHfgPrice(data.price))
          .catch(error => console.error('Error fetching data:', error));
  }

  useEffect(() => {
      const intervalId = setInterval(() => {
          getPrice()
      }, 12000);

      return () => {
          clearInterval(intervalId);
      };
  }, []);

  useEffect(() => {
      getPrice()
  }, []);

  return (
    <>
      <div className="cursor"></div>
      <Cursor /> 
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
          </Route>
        </Routes>
      </BrowserRouter>
       {/* <Popup /> */}
       <div className='hfgPrice'>
       <img src={SpinCoin} className="spincoin me-1" alt="" /> ${Number(hfgPrice) > 0 ? hfgPrice?.substr(0, 5) : 0}
       </div>
      <BigBangStarField
        className="bgBody"
        numStars={150}
        maxStarSpeed={5}
        scale={1}
        starColor="252, 255, 255"
      />
      <LazyLoad className="bgVideoBody" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
        <ReactPlayer
          url={bgVideo}
          width='100%'
          height='100%'
          muted
          loop
          playing={true}
          autoPlay={true}
          playsinline={true}
        />
      </LazyLoad>
    </>
  );
}

export default App;
