import React from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import news1 from '../../Assets/images/news/news1.webp'
import news2 from '../../Assets/images/news/news2.webp'
import news3 from '../../Assets/images/news/news3.webp'
import news4 from '../../Assets/images/news/news4.webp'
import news5 from '../../Assets/images/news/news5.webp'
import news6 from '../../Assets/images/news/news6.webp'
import news7 from '../../Assets/images/news/news7.webp'
import news8 from '../../Assets/images/news/news8.webp'
import news9 from '../../Assets/images/news/news9.webp'
import news10 from '../../Assets/images/news/news10.webp'
import news11 from '../../Assets/images/news/news11.webp'
import news12 from '../../Assets/images/news/news12.webp'
import news13 from '../../Assets/images/news/news13.webp'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function MediaSlider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <Slider {...settings} className="mediasliderbar" id="mediasliderbar">
            <a href="https://twitter.com/PolygonDaily/status/1684123567506743298?t=Re4LAgiUKiJIB4zeaSlo5w&s=19" target="_blank" rel="noreferrer" className="hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news1} alt="news1" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Polygon Daily</h4>
                    </div>
                </div>
            </a>
            <a href="https://youtu.be/tN4PmZmSank" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news2} alt="news2" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>NFTs Guide</h4>
                    </div>
                </div>
            </a>
            <a href="https://www.coingabbar.com/en/crypto-airdrops/hashfair-games-hfg-token-latest-airdrop-giveaway" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news3} alt="news3" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Coingabbar</h4>
                    </div>
                </div>
            </a>
            <a href="https://youtu.be/yZZXpidP0V8" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news4} alt="news4" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>CryptoCoinsNFT Gaming</h4>
                    </div>
                </div>
            </a>
            <a href="https://www.kriptom.com/hashfair-blockchain-destekli-seffaflik-ve-adillik-ile-cevrimici-oyunlarda-devrim/" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news5} alt="news5" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Kriptom</h4>
                    </div>
                </div>
            </a>
            <a href="https://www.youtube.com/watch?v=t2VkkOs_jd4&ab_channel=Goalorious" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news6} alt="news6" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Goalorious</h4>
                    </div>
                </div>
            </a>
            <a href="https://www.youtube.com/@Block-Chain-World/videos" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news7} alt="news7" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Blockchain world</h4>
                    </div>
                </div>
            </a>
            <a href="https://btcmagazin.com/hashfair-blockchain-destekli-seffaflik-ve-adillikle-cevrimici-oyun-dunyasini-devrim-yaratiyor/" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news8} alt="news8" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>BTCMagazin</h4>
                    </div>
                </div>
            </a>
            <a href="https://youtu.be/IjUCZly8mOg" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news9} alt="news9" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Rankoo Crypto</h4>
                    </div>
                </div>
            </a>
            <a href="https://youtu.be/FIl80mHq2Xo?x" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news10} alt="news10" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Crypto Bambino</h4>
                    </div>
                </div>
            </a>
            <a href="https://www.kriptosozluktv.com/2023/08/oyun-dunyasini-merkeziyetsizlestirmek.html" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news11} alt="news11" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Kripto Sözlük</h4>
                    </div>
                </div>
            </a>
            {/* <a href="https://twitter.com/Altcoin100Xgems/status/1689238397506736129?t=xuF0E1-hRhY0NqAtMFCafQ&s=19" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news12} alt="news12" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>Altcoin GEMs</h4>
                    </div>
                </div>
            </a>
            <a href="https://twitter.com/AltcoinGem100x/status/1689259723042770944?t=Ct-b_qMhpBANoI6tyt5uNQ&s=19" target="_blank" rel="noreferrer" className=" hoimg">
                <div className="single_gaming_world">
                    <LazyLoad className="gaming_world_thumb" height={300} once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <div className="border-light">
                            <img width="300" height="300" src={news13} alt="news13" />
                        </div>
                    </LazyLoad>
                    <div className="gaming_world_text">
                        <h4>AltcoinGem100x</h4>
                    </div>
                </div>
            </a> */}
        </Slider>
    );
}