import React from 'react'
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "What is HashFair Games?",
            content: `HashFair Games is the first decentralized gaming platform that operates with a decentralized dealer. It offers a unique gaming experience where the results are calculated using a secure and transparent algorithm based on hash functions.`,
        },
        {
            title: "How does the decentralized dealer work?",
            content: `In HashFair Games, the dealer's role is decentralized, meaning that the outcome of each game is not determined by a centralized entity but by a verifiable algorithm based on hash functions. This ensures fairness and eliminates any possibility of manipulation or bias..`,
        },
        {
            title: "What are the coins in HashFair Games?",
            content: `HashFair Games operates with two types of coins. The first coin is playable known as HUSD, and it is used to participate in the games within the platform. The second coin is won as rewards known as HFG when you win games, and it is cashable, meaning you can exchange it for other cryptocurrencies or fiat currency.`,
        },
        {
            title: "How are the games based on the DAO concept?",
            content: `The games in HashFair Games are built on the DAO (Decentralized Autonomous Organization) concept. This means that the platform operates in a decentralized manner, governed by smart contracts and the consensus of the community. The decisions and rules of the platform are transparent, and the community members have a say in its operation.`,
        },
        {
            title: "How are profits shared within the community?",
            content: `As HashFair Games is community-based, the profits generated by the platform are shared among the participants. The exact profit-sharing mechanism may vary, but typically a portion of the profits is distributed among token holders or active participants in the community. This creates an incentive for users to contribute to the platform's growth and success.`,
        },
        // {
        //     title: "HOW DO I CONNECT MY WALLET TO Hashfair Games?",
        //     content: `In order to connect your wallet you have to click on the "Connect Wallet" button on the top-right corner of the page. After that, look for the wallet you want to connect to Hashfair Games and click "connect". Approve the connection and you're good to go.`,
        // },
        // {
        //     title: "HOW DO I SET UP MY WALLET ON POLYGON CHAIN?",
        //     content: `When you go into the dAPP in the top-right corner you can see the different blockchains available, click on it then choose Polygon Chain Mainnet. MetaMask: Create a custom RPC with the following: Polygon Mainnet in the Network Name field, URL https://polygon-rpc.com/ in the New RPC URL field, 137 in Chain ID field, POL in Currency Symbol field and https://polygonscan.com/ in Block Explorer URL field.`,
        // },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#9b9b9b",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#000000",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <>
            <section className="mb-140 faqss">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2>FAQ's</h2>
                        <p>Got Questions? We've Got Answers! Explore the HashFair FAQ and Discover a World of Clarity.</p>
                    </div>
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </section>
        </>
    )
}

export default Faqs